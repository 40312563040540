import React from 'react'
import { Link } from "gatsby"
import './navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

class NavBar extends React.Component {



    handleNavMenu() {
        let x = document.getElementById("links");
        if (x.className === "links") {
            x.className += " responsive";
        } else {
            x.className = "links";
        }
    }

    render() {
        return (

            <div className="navbar" id="navbar">
                <Link to="/" className="page-title" >Ramirez Concrete</Link>
                {/* <div className="spacer"></div> */}
                
                <a href="javascript:void(0);" className="icon" onClick={this.handleNavMenu}><FontAwesomeIcon icon={faBars} /></a>
                <div className="links" id="links">
                    <Link to="/about">About</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/gallery">Gallery</Link>
                    <Link to="/contact">Contact</Link>

                    
                </div>
            </div>

        );
    }
}

export default NavBar