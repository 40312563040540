import React from 'react'
import './footer.css'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram} from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {

    toTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render(){
        return (
    
            <div className="footer">
            <a href="#" onClick={this.toTop}>Back to top</a>
            <div className="contact-details">
            <h3>Ramirez Concrete</h3>
            <h3>ramirezconcrete@gmail.com</h3>
            <h3>(614) 207-9140</h3>
            
            </div>
            <hr/>
            <div className="social">
            <FontAwesomeIcon icon={faFacebook}/>
            <FontAwesomeIcon icon={faInstagram}/>
           
            </div>
            </div>
)}
}

export default Footer

